import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import styles from './Popup.module.scss';

class Popup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} size="lg">
          <ModalHeader toggle={this.toggle}>Liebe Patienten/innen !</ModalHeader>
          <ModalBody className={styles.shrinktext}>
          bitte beachten Sie unsere <b>neuen</b> Öffnungszeiten.<br></br>
          <br></br>
          Montag von 8:00-12:00 & von 14:00-17:00 Uhr<br></br>
          Dienstag von 9:00-12:30 & von 14:30-18:00 Uhr<br></br>
          Mittwoch von 8:00 – 12:00Uhr<br></br>
          Donnerstag von 8:00-12:00 & von 14:00-17:00 Uhr<br></br>
          Freitags geschlossen<br></br>
          <br></br>
          Emails werden auch Freitags gelesen.<br></br>
          Termine können immer online gebucht werden.
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>OK</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default Popup;