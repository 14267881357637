import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import HeaderSection from '../components/HeaderSection/HeaderSection';
import Section from '../components/Section/Section';
import Headline from '../components/Headline/Headline';
import {Helmet} from "react-helmet";
import Popup from "../components/Popup/Popup";

const IndexPage = () => (
  <Layout headerWhite home>
    <SEO
      title="Orthopädie Dr. Schubert | Mitten in Bochum"
      description="Dr. B. Schubert ist Orthopäde mitten in Bochum. Experte mit über 100.000 Gelenkinjektionen ✓ Mehr als 20 Jahre Berufserfahrung ✓ Moderne Praxis ✓"
    />
    <HeaderSection
      home
    />
    <Container>
      <Section>
        <Headline h1 subtitle="Dr. med. B. Schubert">Orthopädie Praxis Dr. Schubert</Headline>
        <Row style={{paddingTop: 15}}>
          <Col xs={12} lg={6}>
            <p>
              <span className="bigLetter">H</span>
              erzlich Willkommen in unserer hellen und mit Leidenschaft geführten orthopädischen Praxis mitten in Bochum am Dr.-Ruer-Platz. Ein respektvoller Umgang miteinander und Spaß an dem, was wir tun, sind für uns eine Selbstverständlichkeit.
              <br />
              <br />
              Wir sind für Sie da!
              <br />
              Bei allen akuten und chronischen Erkrankungen der Gelenke und Wirbelsäule, mit modernsten diagnostischen und therapeutischen Verfahren, schulmedizinisch klassisch, innovativ und integrativ, von der Prävention bis zur Operation.

            </p>
          </Col>
          <Col xs={12} lg={6}>
            <p style={{ paddingTop: 15 }}>
              Wir nehmen uns Zeit und klären vor einer Behandlung erst alle Fragen.
              So entstehen Sicherheit und Vertrauen, denn jeder Schritt ist nachvollziehbar.
              Nur wenn Sie zufrieden sind und sich gut beraten fühlen, haben wir unser Ziel erreicht.

              <br />
              <br />
              Ihr Dr. Burkhard Schubert und sein Team

            </p>
          </Col>
        </Row>
      </Section>
    </Container>
    <Section dark>
      <Row noGutters>
        <Col xs={12} lg={6}>
          <div className="fullWidth-col-image" style={{ backgroundPosition: 'center top' }} />
        </Col>
        <Col xs={12} lg={6}>
          <div className="fullWidth-col-content fullWidth-col-content-empty right">
            <div className="vertical-center">
              <Headline light subtitle="Zu meiner Person"><Link to="/praxis">Orthopäde Dr. Schubert</Link></Headline>
            </div>
          </div>
        </Col>
      </Row>
    </Section>
  </Layout>
);

export default IndexPage;
